import { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";

import { API } from "aws-amplify";

import { Firm, InputFirmModify } from "reliance-private-sitebuilder-schema/dist/API";

import Confirm, { ModalInfo } from "../components/Confirm";
import Loader from "../components/Loader";

import { getFirm } from "reliance-private-sitebuilder-schema/dist/graphql/queries";

import * as _ from "lodash";

import FirmStore, { updateFirmStore, modifyFirmDetails } from "../stores/FirmStore";
import { useStore } from "effector-react";

import Header from "../components/Header";

import { addFirm, updateFirm } from "reliance-private-sitebuilder-schema/dist/graphql/mutations";

import { notifyRealtime } from "../methods/notifyRealtime";

import { classNames, scrollToTop, menuSectionExistsInErrors, itemExistsInErrors } from "../utils/utils";

import { ToastMessage, ModificationError } from "../types/types";

import { Storage } from "aws-amplify";

import MediaPromise from "../components/MediaPromise";

import { v4 as uuidv4 } from "uuid";

function FirmsModify() {
    type routeParams = {
        firm_id?: string;
    };

    const route_params: routeParams = useParams();

    const firm_store = useStore(FirmStore);
    const [toastMsg, toastMsgSet] = useState<ToastMessage>();
    const [loading, loadingSet] = useState(true);
    const [modalInfo, setModalInfo] = useState<ModalInfo>({
        title: "Title",
        text: "Text",
        positive: "OK",
        negative: "Cancel",
        close: () => {
            setModalInfo({
                ...modalInfo,
                open: false,
            });
        },
        action: () => {},
        open: false,
    });

    useEffect(() => {
        const loadData = async () => {
            var promises: any = {};

            var mode: string = route_params.firm_id ? "EDIT" : "ADD";
            updateFirmStore({
                mode: mode,
                section: "basics",
            });
            var graphQlValues: any;

            if (mode === "EDIT") {
                promises["firm"] = API.graphql({ query: getFirm, variables: { id: route_params.firm_id } });
                try {
                    graphQlValues = await Promise.all([promises["firm"]]);

                    // get pulled Firm
                    var rawFirm = graphQlValues[0].data.getFirm as Firm;

                    // convert to InputFirmModify
                    var inputFirm = _.cloneDeep(rawFirm) as unknown as InputFirmModify;

                    updateFirmStore({
                        holdingFirm: inputFirm,
                    });
                    loadingSet(false);
                } catch (error) {
                    console.log(error);
                }
            } else {
                loadingSet(false);
            }
        };
        loadData();
    }, [route_params]);

    const getMediaItem = async (value: string) => {
        var url = await Storage.get(value);
        return url;
    };

    const handleFirmChange = (fieldName, fieldValue) => {
        modifyFirmDetails({ fieldName: fieldName, fieldValue: fieldValue });
    };

    const imageSize = function (e) {
        const promise = new Promise((resolve, reject) => {
            var reader = new FileReader();
            //Read the contents of Image File.
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = function (e) {
                //Initiate the JavaScript Image object.
                var image = new Image();

                //Set the Base64 string return from FileReader as source.
                image.src = e.target!.result! as string;

                //Validate the File Height and Width.
                image.onload = function () {
                    var height = (this as HTMLImageElement).height;
                    var width = (this as HTMLImageElement).width;
                    resolve({ width, height });
                };
            };
        });

        return promise;
    };

    const onFileChoose = async (e) => {
        let t = "IMAGE";

        const file = e.target.files[0];
        const fileExt = file.type.split("/")[1];

        try {
            var fileId = `${uuidv4()}.${fileExt}`;
            await Storage.put(fileId, file, {
                acl: "bucket-owner-full-control",
                progressCallback(progress) {
                    var update: any = {};
                },
            });
            updateFirmStore({
                holdingFirm: {
                    ...firm_store.holdingFirm,
                    interstitialLogo: fileId,
                },
            });
        } catch (err) {
            alert("Error!");
        }
    };

    const doValidateAndSave = useCallback(async () => {
        scrollToTop();

        /// check for various things
        //////
        var errors: ModificationError[] = [];

        /// check survey name filled in
        if (!firm_store.holdingFirm?.name) {
            errors.push({
                menuSection: ["basics"],
                item: `basics#name`,
                itemDescription: "Basic Section",
                message: `Firm Name missing`,
            });
        }

        if (Object.keys(errors).length > 0) {
            toastMsgSet({ message: "Errors! Please Fix!", type: "ERROR" });
        }

        /// set errors in the state for visual representation
        updateFirmStore({
            errors: errors,
        });

        // if we don't have any errors, time to submit it
        if (Object.keys(errors).length === 0) {
            /// send it
            console.log("send it");
            var promises: any = {};

            let postFirm = _.cloneDeep(firm_store.holdingFirm!);
            console.log(postFirm);
            delete postFirm["__typename"];

            promises["firm"] = API.graphql({
                query: firm_store.mode === "ADD" ? addFirm : updateFirm,
                variables: {
                    input: postFirm,
                },
            });
            try {
                var add_result = await Promise.all([promises["firm"]]);
                toastMsgSet({ message: "Saved Successfully!" });
                notifyRealtime("firm", firm_store.holdingFirm!.id!);
                if (firm_store.mode === "ADD") {
                    setTimeout(function () {
                        window.location.href = `/firms/${(add_result as any)[0].data.addFirm.id}`;
                    }, 2000);
                }
            } catch (error) {
                console.log(error);
            }
        }
    }, [firm_store.holdingFirm, firm_store.mode]);

    return (
        <>
            <Header title={`${firm_store.mode} Firm`} toast={toastMsg} errors={firm_store.errors} />
            <main>
                {loading ? (
                    <Loader />
                ) : (
                    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
                            <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
                                <nav className="space-y-1">
                                    <button
                                        className={classNames(
                                            "w-full text-indigo-700 hover:text-indigo-700 hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium",
                                            menuSectionExistsInErrors("basics", firm_store.errors)
                                                ? "bg-red-100"
                                                : firm_store.section === "basics"
                                                ? "bg-gray-100"
                                                : ""
                                        )}
                                        onClick={() => {
                                            updateFirmStore({ section: "basics" });
                                        }}
                                        aria-current="page"
                                    >
                                        <svg
                                            className="text-indigo-500 group-hover:text-indigo-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                        <span className="truncate">Basics</span>
                                    </button>
                                </nav>
                            </aside>

                            <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
                                {firm_store.section === "basics" && (
                                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                                        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                                            <div>
                                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                                    Basic firm information
                                                </h3>
                                                <p className="mt-1 text-sm text-gray-500">
                                                    After this information is entered and the site is created, we will
                                                    have an ID to work from.
                                                </p>
                                            </div>
                                            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                                <button
                                                    type="button"
                                                    onClick={() => doValidateAndSave()}
                                                    className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                >
                                                    Save
                                                </button>
                                            </div>
                                            <div
                                                className={classNames(
                                                    "grid grid-cols-3 gap-6",
                                                    itemExistsInErrors(`basics#name`, firm_store.errors)
                                                        ? "bg-red-100"
                                                        : ""
                                                )}
                                            >
                                                <div className="col-span-3 sm:col-span-2">
                                                    <label
                                                        htmlFor="name"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        Firm Name
                                                    </label>
                                                    <div className="mt-1 rounded-md shadow-sm flex">
                                                        <input
                                                            type="text"
                                                            name="name"
                                                            id="name"
                                                            value={firm_store.holdingFirm?.name ?? ""}
                                                            onChange={(e) =>
                                                                handleFirmChange(e.target.name!, e.target.value!)
                                                            }
                                                            placeholder="ex: Standard Quiz Firm"
                                                            className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                className={classNames(
                                                    "grid grid-cols-3 gap-6",
                                                    itemExistsInErrors(`basics#displayName`, firm_store.errors)
                                                        ? "bg-red-100"
                                                        : ""
                                                )}
                                            >
                                                <div className="col-span-3 sm:col-span-2">
                                                    <label
                                                        htmlFor="nadisplayNameme"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        Description
                                                    </label>
                                                    <div className="mt-1 rounded-md shadow-sm flex">
                                                        <input
                                                            type="text"
                                                            name="displayName"
                                                            id="displayName"
                                                            value={firm_store.holdingFirm?.displayName ?? ""}
                                                            onChange={(e) =>
                                                                handleFirmChange(e.target.name!, e.target.value!)
                                                            }
                                                            placeholder="ex: CA Terms"
                                                            className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                className={classNames(
                                                    "grid grid-cols-3 gap-6",
                                                    itemExistsInErrors(`basics#website`, firm_store.errors)
                                                        ? "bg-red-100"
                                                        : ""
                                                )}
                                            >
                                                <div className="col-span-3 sm:col-span-2">
                                                    <label
                                                        htmlFor="website"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        Firm Website
                                                    </label>
                                                    <div className="mt-1 rounded-md shadow-sm flex">
                                                        <input
                                                            type="text"
                                                            name="website"
                                                            id="website"
                                                            value={firm_store.holdingFirm?.website ?? ""}
                                                            onChange={(e) =>
                                                                handleFirmChange(e.target.name!, e.target.value!)
                                                            }
                                                            placeholder="ex: http://lawfirm.com"
                                                            className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                className={classNames(
                                                    "grid grid-cols-3 gap-6",
                                                    itemExistsInErrors(`basics#terms`, firm_store.errors)
                                                        ? "bg-red-100"
                                                        : ""
                                                )}
                                            >
                                                <div className="col-span-3 sm:col-span-2">
                                                    <label
                                                        htmlFor="terms"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        Firm Terms and Conditions (Tailwind CSS OK)
                                                    </label>
                                                    <div className="mt-1 rounded-md shadow-sm flex">
                                                        <textarea
                                                            rows={10}
                                                            name="terms"
                                                            id="terms"
                                                            value={firm_store.holdingFirm?.terms ?? ""}
                                                            onChange={(e) =>
                                                                handleFirmChange(e.target.name!, e.target.value!)
                                                            }
                                                            placeholder="ex: http://lawfirm.com"
                                                            className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                className={classNames(
                                                    "grid grid-cols-3 gap-6",
                                                    itemExistsInErrors(`basics#formSubmitTerms`, firm_store.errors)
                                                        ? "bg-red-100"
                                                        : ""
                                                )}
                                            >
                                                <div className="col-span-3 sm:col-span-2">
                                                    <label
                                                        htmlFor="formSubmitTerms"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        Form Submit Terms (DNC, SMS) - (Tailwind CSS OK)
                                                    </label>
                                                    <div className="mt-1 rounded-md shadow-sm flex">
                                                        <textarea
                                                            rows={10}
                                                            name="formSubmitTerms"
                                                            id="formSubmitTerms"
                                                            value={firm_store.holdingFirm?.formSubmitTerms ?? ""}
                                                            onChange={(e) =>
                                                                handleFirmChange(e.target.name!, e.target.value!)
                                                            }
                                                            placeholder=""
                                                            className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <>
                                                <div className="mt-1 rounded-md shadow-sm flex">
                                                    <label
                                                        htmlFor="interstitialLogo"
                                                        className="block text-sm font-medium text-gray-700 pr-2"
                                                    >
                                                        Thank You Page Loading - Logo
                                                    </label>
                                                    <input
                                                        type="file"
                                                        accept="image/png, image/jpeg, image/jpg"
                                                        onChange={(evt) => {
                                                            onFileChoose(evt);
                                                        }}
                                                    />
                                                    <p className="text-red-500">
                                                        Must be <b>JPG or PNG</b>
                                                    </p>
                                                    {firm_store.holdingFirm?.interstitialLogo && (
                                                        <div>
                                                            Current File:{" "}
                                                            <MediaPromise
                                                                getter={async () =>
                                                                    await getMediaItem(
                                                                        firm_store.holdingFirm?.interstitialLogo!
                                                                    )
                                                                }
                                                                mediaType="IMAGE"
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        </div>
                                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                            <button
                                                type="button"
                                                onClick={() => doValidateAndSave()}
                                                className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </main>
            {modalInfo.open && <Confirm info={modalInfo} />}
        </>
    );
}

export default FirmsModify;
